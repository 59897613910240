"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.BlogBreadcrumb = function BlogBreadcrumb() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $printTrigger = document.querySelector('.blog-print');
    if ($printTrigger) {
      $printTrigger === null || $printTrigger === void 0 || $printTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        window.print();
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
};