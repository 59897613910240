"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ConversionFactors = function ConversionFactors() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $mainUnits = document.querySelectorAll('.main-unit-selector p');
  var $dropdownTriggers = document.querySelectorAll('.unit-selector');
  var $mainUnitText = document.querySelector('.selected-main-unit');
  var $converterUnits = document.querySelectorAll('.rate-unit-selector p');
  var $converterUnitText = document.querySelector('.selected-rate-unit');
  var $submitConversion = document.querySelector('[action-submit-conversion]');
  var $conversionText = document.querySelector('[action-convert-result]');
  var $conversionQty = document.querySelector('input[name="conversion-qty"]');
  var $errorMessage = document.querySelector('.calculator-error');
  var converterArray = {
    'Bcfm3': 28316840.000000000000000,
    'BcanGjour': 2939.520000000000000,
    'Bcanmjour': 77580.400000000000000,
    'BtuPi3': 0.001000000000000,
    'GJm3': 26.392000000000000,
    'GJMpc': 0.947816987913438,
    'GjourBcan': 0.000340191595907,
    'm3GJ': 0.037890269778721,
    'm3Bcf': 0.000000035314675,
    'mjourBcan': 0.000012889853623,
    'MpcGJ': 1.055056000000000,
    'Pi3Btu': 1000.000000000000000,
    '$GigaCmetre3': 3.789000000000000,
    'Cmetre3$Giga': 0.263921879123779
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    if ($mainUnits.length && $converterUnits.length && $dropdownTriggers.length && $submitConversion) {
      $dropdownTriggers.forEach(function ($dropdownTrigger) {
        $dropdownTrigger.addEventListener('click', function () {
          $dropdownTrigger.querySelector('.unit-dropdown').classList.toggle('is-active');
          $dropdownTrigger.querySelector('.dropdown-arrow').classList.toggle('is-active');
        });
      });
      var currentMain = '';
      $mainUnits.forEach(function ($mainUnit) {
        $mainUnit.addEventListener('click', function () {
          currentMain = $mainUnit.dataset.mainUnit;
          $mainUnitText.textContent = currentMain;
          $mainUnitText.classList.remove('is-placeholder');
          $mainUnitText.previousElementSibling.classList.add('is-active');
          var _iterator = _createForOfIteratorHelper($converterUnits),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var $converterUnit = _step.value;
              var currentUnitArray = $converterUnit.dataset.availableUnit;
              if (currentUnitArray.includes(currentMain)) {
                $converterUnit.classList.remove('is-disabled');
              } else {
                $converterUnit.classList.add('is-disabled');
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
        });
      });
      var currentUnit = '';
      var currentUnitTitle = '';
      $converterUnits.forEach(function ($converterUnit) {
        $converterUnit.addEventListener('click', function () {
          currentUnit = $converterUnit.dataset.rateUnit;
          $converterUnitText.textContent = currentUnit;
          currentUnitTitle = $converterUnit.dataset.unitName;
          $converterUnitText.classList.remove('is-placeholder');
          $converterUnitText.previousElementSibling.classList.add('is-active');
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
        });
      });
      ['keyup', 'keydown', 'change', 'blur'].forEach(function (event) {
        $conversionQty.addEventListener(event, function () {
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
          if ($conversionQty.value) {
            $conversionQty.previousElementSibling.classList.add('is-active');
          } else {
            $conversionQty.previousElementSibling.classList.remove('is-active');
          }
        });
      });
      $submitConversion.addEventListener('click', function () {
        var convertRate = converterArray[currentMain + currentUnit];
        if (convertRate) {
          var resultTotal = "".concat(convertRate * $conversionQty.value);
          $conversionText.textContent = new Intl.NumberFormat('en-CA', {
            maximumSignificantDigits: 3
          }).format(resultTotal) + " ".concat(currentUnitTitle);
          $errorMessage.classList.remove('is-visible');
          document.querySelector('.result-wrapper').classList.add('is-active');
        } else {
          $errorMessage.classList.add('is-visible');
        }
      });
    }
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();