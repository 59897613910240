"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.FilesUploads = function FilesUploads() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var fileUploadFields = document.querySelectorAll('.custom-file-upload');
    fileUploadFields.forEach(function (field) {
      var fieldId = field.dataset.fieldId;
      var dropZone = field.querySelector("#dropZone_".concat(fieldId));
      var fileInput = field.querySelector("#fileInput_".concat(fieldId));
      var fileList = field.querySelector("#fileList_".concat(fieldId));
      var files = new Map(); // Use a Map to store files

      dropZone.addEventListener('click', function () {
        return fileInput.click();
      });
      dropZone.addEventListener('dragover', function (e) {
        e.preventDefault();
        dropZone.classList.add('dragover');
      });
      dropZone.addEventListener('dragleave', function () {
        dropZone.classList.remove('dragover');
      });
      dropZone.addEventListener('drop', function (e) {
        e.preventDefault();
        dropZone.classList.remove('dragover');
        handleFiles(e.dataTransfer.files);
      });
      fileInput.addEventListener('change', function (e) {
        handleFiles(e.target.files);
      });
      function handleFiles(newFiles) {
        var _iterator = _createForOfIteratorHelper(newFiles),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var file = _step.value;
            if (!files.has(file.name)) {
              files.set(file.name, file);
              addFileToList(file);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        updateFileInput();
      }
      function addFileToList(file) {
        var fileItem = document.createElement('div');
        fileItem.className = 'file-item';
        fileItem.innerHTML = "\n                  <span>".concat(file.name, "</span>\n                  <button type=\"button\" class=\"remove-file\">&times;</button>\n              ");
        fileList.appendChild(fileItem);
        fileItem.querySelector('.remove-file').addEventListener('click', function () {
          fileList.removeChild(fileItem);
          files.delete(file.name);
          updateFileInput();
        });
      }
      function updateFileInput() {
        var dataTransfer = new DataTransfer();
        files.forEach(function (file) {
          dataTransfer.items.add(file);
        });
        fileInput.files = dataTransfer.files;
        //   console.log('Updated files:', fileInput.files);
      }
    });
    return true;
  };
  // Expose public methods & properties
  return {
    init: init
  };
}();